<template>
  <section
    id="modalInvoice"
    class="invoice-preview-wrapper"
  >
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    v-bind="logoClass"
                    :src="logo"
                    alt="Logo"
                  />
                </div>
                <p class="card-text mb-25">
                  Dusun Kangkungan RT.7 RW.5, Lengkong, <br> Kec. Mojoanyar, Mojokerto, Jawa Timur
                </p>
                <p class="card-text mb-0">
                  0812-6005-0010
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">#PS-{{ invoiceData.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Tanggal Transaksi:
                  </p>
                  <p class="invoice-date">
                    {{ readableDate(invoiceData.created_at) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex"
              >
                <div>
                  <h6 class="mb-2">
                    Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Tanggal Berangkat:
                        </td>
                        <td><span class="font-weight-bold">{{ invoiceData.tanggal_berangkat }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Tanggal Pulang:
                        </td>
                        <td><span class="font-weight-bold">{{ invoiceData.tanggal_balik }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Pembayaran:
                        </td>
                        <td>{{ invoiceData.status_pembayaran_supir === 'lunas' ? 'Lunas' : 'Belum Lunas' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="invoiceDescription"
            :fields="['keterangan', 'nama_supir', 'tujuan', 'titik_jemput', 'total']"
          >
            <template #cell(keterangan)="">
              <b-card-text class="font-weight-bold mb-25">
                Pembayaran Ke Supir a/n {{ invoiceData.supir.nama }}
              </b-card-text>
            </template>

            <template #cell(nama_supir)="">
              <b-card-text class="font-weight-bold mb-25">
                {{ invoiceData.supir.nama }}
              </b-card-text>
            </template>

            <template #cell(tujuan)="">
              <b-card-text class="font-weight-bold mb-25">
                {{ invoiceData.tujuan }}
              </b-card-text>
            </template>

            <template #cell(titik_jemput)="">
              <b-card-text class="font-weight-bold mb-25">
                {{ invoiceData.titik_penjemputan }}
              </b-card-text>
            </template>

            <template #cell(total)="">
              <b-card-text class="font-weight-bold mb-25">
                {{ invoiceData.jumlah_pembayaran_supir | formatRupiah }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <!-- <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text> -->
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <!-- <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      $1800
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      $28
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Tax:
                    </p>
                    <p class="invoice-total-amount">
                      21%
                    </p>
                  </div> -->
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ invoiceData.jumlah_pembayaran_supir | formatRupiah }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, VBToggle, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import axios from '@axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BImg,
  },
  props: {
    pembayaran: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // eslint-disable-next-line global-require
    const logo = require('@/assets/images/logo/verena-teks.png')
    const invoiceData = props.pembayaran
    const paymentDetails = ref({})
    // const currentId = this.$router.currentRoute.params.id

    // Invoice Description
    // ? Your real data will contain this information
    const invoiceDescription = [
      {
        taskTitle: '-',
      },
    ]

    // axios.get(`booking/${this.$router.currentRoute.params.id}`)
    //   .then(response => {
    //     invoiceData = response.data.data
    //   })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       invoiceData = undefined
    //     }
    //   })

    const printInvoice = () => {
      const modal = document.getElementById('modalInvoice')
      const cloned = modal.cloneNode(true)
      let section = document.getElementById('print')

      if (!section) {
        section = document.createElement('div')
        section.id = 'print'
        document.body.appendChild(section)
      }

      section.innerHTML = ''
      section.appendChild(cloned)
      setTimeout(() => {
        window.print()

        setTimeout(() => {
          section.remove()
        }, 0)
      }, 0)
    }

    const readableDate = data => {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric',
      }
      return new Date(data).toLocaleDateString(undefined, options)
    }

    const logoClass = {
      width: 200,
      height: 80,
    }

    return {
      invoiceData,
      paymentDetails,
      invoiceDescription,
      printInvoice,
      readableDate,
      logo,
      logoClass,
      // currentId,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media screen {
  #print {
    display: none;
  }
}

@media print {

  body * {
    visibility:hidden;
    max-height: 100vh;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
  // Global Styles
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .d-flex {
    display: flex;
  }

  .flex-md-row {
    flex-direction: row !important;
  }
}
</style>
