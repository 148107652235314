<template>
  <div>
    <b-sidebar
      id="add-kas-sidebar"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      :visible="addPembayaranSupirSidebar"
      right
      no-header
      backdrop
      @hidden="(val) => changeValue(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            Pembayaran Supir
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Body -->
        <validation-observer
          ref="simpleRules"
          class="px-2"
        >
          <b-form>
            <b-row class="px-2">
              <b-col md="12">
                <b-form-group
                  label="Supir"
                  label-for="supir-pembayaran-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Supir"
                    rules="required"
                  >
                    <b-form-select
                      id="supir-pembayaran-supir"
                      v-model="pembayaranSupirLocal.supir"
                      :options="optionsSupir"
                      :disabled="pembayaranSupirLocal.id > 0"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Status Supir"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Tujuan"
                  label-for="tujuan-pembayaran-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tujuan"
                    rules="required"
                  >
                    <b-form-input
                      id="tujuan-pembayaran-supir"
                      v-model="pembayaranSupirLocal.tujuan"
                      :disabled="pembayaranSupirLocal.id > 0"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Tujuan"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Jumlah"
                  label-for="jumlah-pembayaran-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Jumlah"
                    rules="required"
                  >
                    <b-form-input
                      id="jumlah-pembayaran-supir"
                      v-model="pembayaranSupirLocal.jumlah"
                      :disabled="pembayaranSupirLocal.id > 0"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Jumlah"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="12">
                <b-form-group
                  label="Tanggal"
                  label-for="tanggal-pembayaran-supir"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tanggal"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="tanggal"
                      v-model="pembayaranSupirLocal.tanggal"
                      :state="errors.length > 0 ? false:null"
                      class="mb-1"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <!-- <b-col
                v-show="pembayaranSupirLocal.id > 0"
                md="12"
              >
                <b-form-group
                  label="Upload Bukti Pembayaran Gaji"
                  label-for="foto-bukti"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upload Bukti Pembayaran Gaji"
                    :rules="pembayaranSupirLocal.id > 0 ? 'required' : ''"
                  >
                    <b-form-file
                      id="foto-bukti"
                      v-model="pembayaranSupirLocal.foto_nota"
                      accept="image/*"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Pilih File"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col md="12">
                <b-button
                  variant="primary"
                  class="mr-1"
                  @click="submit()"
                >
                  Submit
                </b-button>
                <b-button
                  variant="outline-secondary"
                  @click="changeValue(false)"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BButton, BCol,
  BRow, BSidebar, BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    BCol,
    BRow,
    BSidebar,
    BFormSelect,
  },
  model: {
    prop: 'addPembayaranSupirSidebar',
  },
  props: {
    addPembayaranSupirSidebar: {
      type: Boolean,
      required: true,
    },
    pembayaranSupir: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      pembayaranSupirLocal: {
        id: null,
        supir: null,
        keterangan: null,
        jumlah: null,
        tanggal: null,
      },
      optionsSupir: [],
      optionsBis: [],
    }
  },
  watch: {
    pembayaranSupir() {
      this.resetTaskLocal()
    },
  },
  mounted() {
    this.getDataSupir()
  },
  methods: {
    getDataSupir() {
      this.$http.get('/supir-data').then(response => {
        // eslint-disable-next-line array-callback-return
        response.data.data.map(data => {
          const dataSupir = {
            value: data.id,
            text: data.nama,
          }
          this.optionsSupir.push(dataSupir)
        })
      })
    },
    resetTaskLocal() {
      this.pembayaranSupirLocal = {
        id: this.pembayaranSupir.id,
        supir: this.pembayaranSupir.supir.id,
        tujuan: this.pembayaranSupir.tujuan,
        jumlah: this.pembayaranSupir.jumlah_pembayaran_supir,
      }
    },
    changeValue() {
      const data = {
        id: null,
        supir: null,
        keterangan: null,
        jumlah: null,
        tanggal: null,
      }
      this.$emit('pembayaran-supir-sidebar-change', data, false)
    },
    submit() {
      this.$emit('submit-data-pembayaran-supir', this.pembayaranSupirLocal)
    },
  },
}
</script>
<style>
.b-sidebar-body {
  overflow: hidden !important;
}
</style>
